// ** React & Mui
import { useEffect, useState } from 'react';
import { Avatar, Box, Grid, Stack, Typography, useTheme } from '@mui/material';

// ** Components
import UniswapCard from '@/components/uniswap/UniswapCard';

// ** Context & Utils
import { useBackend } from '@/context/backend';
import { ProtocolEnum } from '@/types/custom';
import { TopTopicMobileCard } from '@/components/comm-dashboard/top-topic/TopTopicMobileCard';
const OverviewMobile = () => {
  const theme = useTheme();
  const {
    getTopics,
    fetchUniswapData
  } = useBackend();
  const [isLoading, setIsLoading] = useState(false);
  const [topTopic, setTopTopic] = useState();
  const [topTokens, setTopTokens] = useState();
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchTopTopic();
      await fetchTokensData();
      setIsLoading(false);
    };
    fetchData();
  }, []);
  const fetchTopTopic = async () => {
    try {
      const topics = await getTopics(ProtocolEnum.LENS);
      if (!topics) return;
      setTopTopic(topics[0]);
    } catch (error) {
      console.log('Error while fetching top topic', error);
    }
  };
  const fetchTokensData = async () => {
    try {
      const tokens = await fetchUniswapData(undefined, 1, 20);
      if (!tokens) return;
      const filteredTokens = tokens.slice(0, 3);
      setTopTokens(filteredTokens);
    } catch (error) {
      console.log('Error while fetching top topic', error);
    }
  };
  return <Box data-sentry-element="Box" data-sentry-component="OverviewMobile" data-sentry-source-file="index.tsx">
      <TopTopicMobileCard topicData={topTopic} isLoading={isLoading} data-sentry-element="TopTopicMobileCard" data-sentry-source-file="index.tsx" />
      <Stack direction="row" paddingLeft="1em" mt="1em" alignContent="center" gap={0.5} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <Avatar src="images/icons/token.png" sx={{
        backgroundColor: '#fff',
        padding: !'0.02px',
        borderRadius: '50%'
      }} data-sentry-element="Avatar" data-sentry-source-file="index.tsx" />
        <Typography variant="h6" alignContent="center" sx={{
        position: 'relative',
        zIndex: 2,
        backgroundColor: 'transparent'
      }} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          Top tokens
        </Typography>
      </Stack>
      <Stack data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <Grid container spacing={2} justifyContent="center" sx={{
        overflow: 'hidden',
        mt: '0.5em',
        p: '0.8em',
        backgroundColor: theme.palette.mode === 'dark' ? '#39414e' : '#f2f2f2',
        borderRadius: '0.5rem'
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {topTokens?.map(token => <Grid item key={`${token.blockChain}-${token.token_symbol}`} sm={6}>
              <UniswapCard token={token} />
            </Grid>)}
        </Grid>
      </Stack>
    </Box>;
};
export default OverviewMobile;