import { UserSession } from './apps/users'
import { PublicationMainFocus } from '@lens-protocol/metadata'
import { PostFragment } from '@lens-protocol/client'

export type Months =
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December'

export enum UserSessionStatus {
  Logged = 'logged-in',
  ConnectedNotLogged = 'connected-not-logged-in',
  NotLogged = 'not-logged',
  Loading = 'loading',
}

export enum StatusEnum {
  idle = 'idle',
  loading = 'loading',
  success = 'success',
  error = 'error',
}

export type ProjectModeType = 'production' | 'development'

/**
 * Sign in status
 * @description The status of the sign-in process.
 *
 * - undefined: default status.
 * - "pending": remains signature or something to complete.
 * - "loading": waiting for an internal response.
 * - "completed": sign-in process completed successfully.
 * - "rejected": user has denied the sign-in process
 */
export enum SignInStatus {
  pending = 'pending',
  loading = 'loading',
  completed = 'completed',
  rejected = 'rejected',
}

export type UserProfileData = Omit<UserSession, 'address'>

export type ProfileItem = {
  __typename: 'Profile'
  id: string
  handle?: {
    __typename: string
    localName: string
  }
  ownedBy: {
    address: `0x${string}`
  }
  stats?: {
    __typename: string
    followers: number
    following: number
    lensClassifierScore: number
    posts: number
  }
  metadata?: {
    __typename: string
    bio: string
    coverPicture: string | null
    displayName: string
    picture: {
      __typename: string
      optimized?: {
        __typename: string
        uri: string
      }
    }
  }
}

export type FeedItem =
  | FollowFeedItem
  | CollectFeedItem
  | PostFeedItem
  | ZoraFeedItem
  | PodsFeedItem

export type FollowFeedItem = {
  type: 'follow'
  id: string
  timestamp: string
  profiles: {
    followedProfile: ProfileItem
    followers: ProfileItem[]
  }
}

export type CollectFeedItem = {
  type: 'collect'
  id: string
  timestamp: string
  data: {
    publicationId: string
  }
  profiles: {
    collectedProfile: ProfileItem
    collectors: ProfileItem[]
  }
}

export type PostFeedItem = {
  type: 'post'
  id: string
  timestamp: string
  data: {
    publicationId: string
    category: string
    publication: PostFragment
  }
}

export type ZoraFeedItem = {
  type: 'zora-mint'
  id: string
  timestamp: string
  data: {
    collectionAddress: `0x${string}`
    tokenId: string
  }
  profiles: {
    collectors: ProfileItem[]
  }
}

export type PodsFeedItem = {
  type: 'pods-mint'
  id: string
  timestamp: string
  data: {
    metadataURI: string
    podId: string
    tokenId: string
  }
  profiles: {
    collectors: ProfileItem[]
  }
}

export type ChartDataProps = {
  labels: string[]
  seriesData: number[]
}

export type TrendingCollectsType = {
  type: PublicationMainFocus
  createdAt: string
  publicationId: string
  ratio: 'FreeCollects' | 'PaidCollects'
  ratioCount: number
  topCollectors: {
    id: number
    publicationId: string
    topCollectorProfileId: string
    createdAt: string
    profileMetadata: ProfileItem
  }[]
}

export type TrendingUserType = {
  profileId: string
  ratio: string
  ratioCount: string
  interest: string
  user: ProfileItem
  topFollowers: ProfileItem[]
}

export type ZoraTrendingType = {
  chain: string
  tokenId: string
  collectionAddress: string
  mintCount: number
}

export type LowerCaseZoraTrendingType = {
  chain: string
  tokenid: string
  collectionaddress: string
  mintcount: number
}

export type PodsTrendingType = {
  tokenId: string
  podId: string
  metadataURI: string
  mint_count: string
}

export type CategoryType = {
  id: number
  name: string
}

export type CategoryByDate = {
  category: string
  count: string
}

export type PeopleTableType = {
  followers: {
    users: TrendingUserType[]
    pagination: { page: number; total: number; totalPages: number }
  }
  likes: {
    users: TrendingUserType[]
    pagination: { page: number; total: number; totalPages: number }
  }
  collects: {
    users: TrendingUserType[]
    pagination: { page: number; total: number; totalPages: number }
  }
}

export type PodMetadataType = {
  version: string
  name: string
  description: string
  image: string
  properties: {
    Collection: string
    Podcast: string
    guest_1: { name: string; value: string }
    host_1: { name: string; value: string }
    host_2: { name: string; value: string }
  }
  animation_url: string
  external_url: string
  episodeTitle: string
  podcast: string
  collection: string
  episodeNumber: number
  artwork: {
    kind: string
    type: string
    uri: string
  }
  primaryMedia: {
    kind: string
    type: string
    uri: string
    duration: number
  }
  publishedAt: string
  credits: Array<{ name: string; role: string }>
}

export interface FollowersHistory {
  date: Date
  newFollowers: number
  totalFollowers: number
}

export interface FollowerHistogram {
  bin: number
  min_followers: number
  max_followers: number
  profile_count: number
}

export interface FarcasterRank {
  profileId: number
  rank: number
}

export interface IRank {
  rank: number
  percentileCategory: string
}

export interface ProfileRank {
  followerRank: IRank
  engagementRank: IRank
  likesRank: IRank
}

export interface Follower {
  profileFollowerId: number
  followedAt: Date
}

export interface RecentFollowers {
  followers: Follower[]
  nextCursor: string
  total: number
}

export interface LinksCount {
  linksCount: number
  fid: number
}

export interface Cast {
  id: number
  fid: number
  hash: string
  type: string
  text: string
  parentUrl: string
  parentCastFid: number
  parentCastHash: string
  createdAt: Date
}

export interface CastPage {
  casts: Cast[]
  limit: number
  skip: number
  total: number
}

export interface IFarcasterFollowingMatchLens {
  handle: string
  profileId: string
}

export interface IFarcasterMatchLens {
  followings: IFarcasterFollowingMatchLens[]
  total: number
}

export interface UniswapTokenData {
  blockChain: string
  tokenBoughtAddress: `0x${string}`
  token_decimals: number
  token_image: string | null
  token_symbol: string
  totalAmountUSD: number
  buyerCount: number
}

export const enum ProtocolEnum {
  LENS = 'Lens',
  FARCASTER = 'Farcaster',
}
