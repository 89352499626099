// ** React & Mui
import { Box, Card, Typography, Button, Stack, Avatar, Chip, useTheme, useMediaQuery, ChipProps } from '@mui/material';

// ** Context & Utils
import { useModalsActions } from '@/context/modals';
import { UniswapWidgetPayload } from '../modals/uniswap-widget';
import { getChainByNetworkName } from '@/utils/uniswap/constants';
import { useSession } from '@/context/session';
import { NotConnectedCase } from '../modals/not-connected';
interface TokenProps {
  token: {
    blockChain: string;
    tokenBoughtAddress: string;
    token_image: string | null;
    token_symbol: string;
    token_decimals: number;
    totalAmountUSD: number;
    buyerCount: number;
  };
}
const getNetworkChipColor = (network: string) => {
  const networkColors = {
    polygon: {
      bgcolor: '#8626D1',
      color: 'white'
    },
    arbitrum: {
      bgcolor: '#0043A9',
      color: '#9EC5FF'
    },
    optimism: {
      bgcolor: '#E4080A',
      color: 'white'
    },
    base: {
      bgcolor: '#0167FF',
      color: 'white'
    }
  };
  return networkColors[network as keyof typeof networkColors] || null;
};
const UniswapCard = ({
  token
}: TokenProps) => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {
    open: openModal
  } = useModalsActions();
  const {
    notLoggedIn,
    connectedNotLogged
  } = useSession();
  const handleBuyToken = () => {
    if (notLoggedIn) {
      openModal('notConnected', {
        modalCase: NotConnectedCase.NotConnected
      });
      return;
    }
    if (connectedNotLogged) {
      openModal('notConnected', {
        modalCase: NotConnectedCase.PendingLogIn
      });
      return;
    }
    const tokenChain = getChainByNetworkName(token.blockChain);
    if (!tokenChain) return;
    const uniswapPayload: UniswapWidgetPayload = {
      chainId: tokenChain.id,
      tokenAddress: token.tokenBoughtAddress,
      tokenDecimals: token.token_decimals,
      tokenSymbol: token.token_symbol,
      tokenImg: token.token_image ?? undefined
    };
    openModal('uniswapWidget', uniswapPayload);
  };
  const chipSx: ChipProps['sx'] = {
    fontStyle: 'italic',
    fontWeight: 700,
    px: 1,
    height: 28,
    ...getNetworkChipColor(token.blockChain),
    opacity: 0.9
  };
  return <Card sx={{
    marginTop: '1em',
    position: 'relative',
    maxWidth: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;',
    minHeight: '14em',
    bgcolor: theme.palette.background.default,
    borderRadius: ' 1em',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      transition: 'background-color 0.3s'
    }
  }} className="hoverCard" data-sentry-element="Card" data-sentry-component="UniswapCard" data-sentry-source-file="UniswapCard.tsx">
      <Box sx={{
      p: {
        xs: 2,
        sm: 3
      }
    }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
        <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'} gap={{
        xs: 2,
        md: 3
      }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
          {/* Header */}
          <Box display="flex" alignItems="center" justifyContent="space-between" width={'100%'} gap={0.5} marginTop={-4} position="relative" data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
            <Avatar src={token.token_image ?? 'images/icons/token.png'} alt={token.token_symbol} sx={{
            position: 'absolute',
            top: '-20px',
            left: '0px',
            transform: 'translateX(-50%)',
            width: 64,
            height: 64,
            zIndex: 1,
            backgroundColor: !token.token_image ? '#fff' : 'transparent',
            padding: !token.token_image ? '0.02px' : '0',
            borderRadius: '50%'
          }} data-sentry-element="Avatar" data-sentry-source-file="UniswapCard.tsx" />
            <Typography variant="h5" component="div" sx={{
            paddingLeft: '40px'
          }} data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
              {token.token_symbol}
            </Typography>
            <Chip label={token.blockChain} size={isSmScreen ? 'small' : 'medium'} sx={chipSx} data-sentry-element="Chip" data-sentry-source-file="UniswapCard.tsx" />
          </Box>

          {/* Cards Grid */}
          <Box sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: {
            xs: 2,
            md: 3
          },
          width: '100%'
        }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
            {/* Sales Card */}
            <Box sx={{
            bgcolor: theme.palette.mode === 'dark' ? '#39414e' : '#f2f2f',
            p: {
              xs: 1,
              md: 2
            },
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
              <Stack direction="row" justifyContent="space-between" mb={2} data-sentry-element="Stack" data-sentry-source-file="UniswapCard.tsx">
                <Box textAlign="center" display="flex" flexDirection="column" gap={0.5} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
                  <Typography variant="body1" fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                    Sales volume
                  </Typography>
                  <Stack direction="row" gap={0.5} data-sentry-element="Stack" data-sentry-source-file="UniswapCard.tsx">
                    <Typography variant="h6" color="#2EE7D1" fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                      ${Math.round(token.totalAmountUSD).toLocaleString()}
                    </Typography>
                    <Typography data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">🔥</Typography>
                  </Stack>
                </Box>
              </Stack>
            </Box>

            {/* Lens Users Card */}
            <Box sx={{
            bgcolor: theme.palette.mode === 'dark' ? '#39414e' : '#f2f2f',
            p: 2,
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2
          }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
              <Stack spacing={1} alignItems="center" width="100%" data-sentry-element="Stack" data-sentry-source-file="UniswapCard.tsx">
                <Typography variant="body1" component="div" fontWeight="bold" textAlign="center" data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                  Bought by <br />
                  <Stack display="flex" justifyContent="center" gap={0.5} direction="row" data-sentry-element="Stack" data-sentry-source-file="UniswapCard.tsx">
                    <Typography variant="h6" marginTop="0.2em" style={{
                    fontWeight: 'bold',
                    color: '#2EE7D1'
                  }} data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                      {token.buyerCount}
                    </Typography>
                    <Typography data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">🚀</Typography>
                  </Stack>
                </Typography>
                <Typography variant="body2" color="text.primary" textAlign="center" data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                  Lens users 24hs
                </Typography>
              </Stack>
            </Box>
          </Box>

          {/* Buy Button */}
          <Button variant="outlined" color="warning" size="large" sx={{
          width: '56%',
          textTransform: 'none',
          fontSize: '1rem',
          borderWidth: '2px',
          borderColor: '#ff9800',
          padding: '8px'
        }} onClick={handleBuyToken} data-sentry-element="Button" data-sentry-source-file="UniswapCard.tsx">
            Buy
          </Button>
        </Box>
      </Box>
    </Card>;
};
export default UniswapCard;